import meta from './meta';
import {getEnvFromHost} from 'candidate/shared/utils/env';

const config = host => {
  const env = getEnvFromHost(host);
  return env === 'production'
    ? import(/* webpackMode: "eager", webpackChunkName: "config" */ './production')
    : import(
        /* webpackInclude: /\.js$/, webpackExclude: /\.test\.js$/, webpackMode: "lazy-once", webpackChunkName: "config" */
        `./${env}`
      );
};

const getConfigWithMeta = async () => {
  const _config = await config();

  return {
    ..._config,
    meta
  };
};

export {meta, config};

export default getConfigWithMeta();
